import React from "react";

const Post = (props) => {
  const { data } = props;

  const renderTags = () => {
    if (data.tags) {
      const fetchTags = data.tags.map((tag) => {
        return <p className="tags">{tag}</p>;
      });

      return fetchTags;
    }
  };
  return (
    <div className="post">
      <img src={data.featImage} alt={data.client} />
      <div className="description">
        <h2>{data.client}</h2>
        <div className="tag-container">{renderTags()}</div>
      </div>
    </div>
  );
};

export default Post;
