import React, { createContext } from "react";
import { db } from "../firebase.js";

export const PostsContext = createContext();

export const PostsProvider = ({ children }) => {
  const postRef = db.collection("post");

  const [posts, setPosts] = React.useState([]);
  const [currentPost, setCurrentPost] = React.useState();
  React.useEffect(() => {}, []);

  const fetchPosts = async () => {
    const postSnapshot = await postRef.get();
    setPosts(postSnapshot.docs.map((doc) => doc.data()));
  };

  React.useEffect(() => {
    fetchPosts();
  }, []);

  //   const addTodo = (todoName, priority) => {
  //     const uid = generateUID();
  //     postRef
  //       .doc(uid)
  //       .set({
  //         name: todoName,
  //         uid: uid,
  //         priority: priority,
  //       })
  //       .then(() => {
  //         fetchPosts();
  //       });
  //   };

  //   const deleteTodo = (uid) => {
  //     postRef
  //       .doc(uid)
  //       .delete()
  //       .then(() => fetchPosts());
  //   };

  return (
    <PostsContext.Provider
      value={{
        posts,
        setCurrentPost,
        setPosts,
      }}
    >
      {children}
    </PostsContext.Provider>
  );
};
