import * as firebase from "firebase/app";
import "firebase/firestore";

export const app = firebase.initializeApp({
  apiKey: "AIzaSyBj5-ra_cuWDCQJqzk8HImznLbfq37lDZ0",
  authDomain: "tylerhahn-portfolio.firebaseapp.com",
  databaseURL: "https://tylerhahn-portfolio.firebaseio.com",
  projectId: "tylerhahn-portfolio",
  storageBucket: "tylerhahn-portfolio.appspot.com",
  messagingSenderId: "690487287386",
  appId: "1:690487287386:web:7cdef355a7002bb3c48263",
  measurementId: "G-ZD8Z4TG1RM",
});

export const db = app.firestore();
