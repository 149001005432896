import React from "react";
import dribbble from "../image/dribbble-brands.svg";
import instagram from "../image/instagram-brands.svg";
const Header = () => {
  return (
    <header>
      <div className="container">
        <div className="brand">
          <h1>Tyler Hahn</h1>
        </div>
        <nav>
          <div className="nav-item">
            <a href="https://dribbble.com/catharsis">
              <img src={dribbble} alt="Dribbble" />
            </a>
          </div>
          <div className="nav-item">
            <a href="https://www.instagram.com/tylerjameshahn">
              <img src={instagram} alt="Instagram" />
            </a>
          </div>
          <div className="nav-item">
            <a href="mailto:inbox@tylerhahn.com?subject=Project Inquiry">
              Hire
            </a>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
