import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";
import { PostsProvider } from "./context/Posts";

function App() {
  return (
    <div className="App">
      <PostsProvider>
        <Header />
        <Home />
        <Footer />
      </PostsProvider>
    </div>
  );
}

export default App;
