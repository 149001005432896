import React from "react";
import { PostsContext } from "../context/Posts";
import Post from "./Post";
import _ from "lodash";
import Masonry from "react-masonry-css";

const Home = () => {
  const { posts, currentPost } = React.useContext(PostsContext);

  const masonryOptions = {
    transitionDuration: 200,
  };

  const renderPosts = () => {
    const fetchPosts = _.map(posts, (post) => {
      return <Post data={post} />;
    });

    return fetchPosts;
  };

  const renderMasonry = () => {
    const breakpointColumnsObj = {
      default: 2,
      1100: 2,
      700: 2,
      500: 1,
    };

    if (posts.length > 0) {
      return (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {renderPosts()}
        </Masonry>
      );
    }
  };

  return (
    <div className="main">
      <div className="intro">
        <div className="container">
          <p>
            Tyler Hahn is a <em>designer</em>, <em>developer</em>, and{" "}
            <em>printmaker</em> working from Milwaukee, WI. His clients include{" "}
            <b>Penguin Random House</b>, <b>RCA</b>, <b>Columbia Records</b>,{" "}
            <b>Superfly</b>, and <b>FX Networks</b>.
          </p>
        </div>
      </div>
      {renderMasonry()}
    </div>
  );
};

export default Home;
